import React, { useEffect } from "react";
import Loader from "../components/loader";
import { useApiCall } from "../hooks/fetch_hooks";
import { api_normal } from "../utils/helpers";
import { config } from "../utils/constants";
import axios from "axios";
import { useAlert } from "react-alert";
import { useHistory } from "react-router-dom";
export const AcceptLoanApproval = () => {
    const callApi = useApiCall();
    const history = useHistory();
    const alert = useAlert();
    useEffect(() => {
        console.log(history.location.search);
        const query = new URLSearchParams(history.location.search);
        console.log(query, query.toString());
        const token = query.get("token");
        const accept = query.get("accept") === "true";
        const loanId = query.get("loan_id");
        callApi.callApi(
            () =>
                axios.get(
                    `${config.loanxUrl}/loan/approved-loan/${
                        accept ? "approve" : "decline"
                    }/${loanId}`,
                    {
                        headers: {
                            Authorization: token,
                        },
                    }
                ),
            (data) => {
                alert.success("Successfully Accepted Loan Offer");
                history.push("/orders");
            },
            (e) => {
                alert.error(e.errorMessage || "Something went wrong!");
                history.push("/");
            }
        );
    }, []);
    return <Loader />;
};
