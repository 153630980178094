import React, { lazy, Suspense, useEffect } from "react";

import { positions, Provider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import { FallBack } from "./components/fallback";
import LoginGoogle from "./components/LoginGoogle";
import { NetworkMonitor } from "./components/network_monitor";
import { UserProvider } from "./contexts/user_context";
import { AcceptLoanApproval } from "./pages/acceptLoanApproval";
import Login from "./pages/auth/login.js";
import Register from "./pages/auth/register.js";
import { dashboardRoutes, MainPage } from "./pages/Main";

const options = {
    timeout: 10000,
    position: positions.TOP_CENTER,
    transition: "scale",
};

function App() {
    useEffect(() => {
        var MessageBirdChatWidgetSettings = {
            widgetId: "2863d1de-bdfc-458c-a1f9-b268f5f62070",
            initializeOnLoad: true,
        };
        (function () {
            if (Boolean(document.getElementById("live-chat-widget-script")))
                console.error(
                    "MessageBirdChatWidget: Snippet loaded twice on page"
                );
            else {
                var e, t;
                window.MessageBirdChatWidget = {};
                window.MessageBirdChatWidget.queue = [];
                for (
                    var i = [
                            "init",
                            "setConfig",
                            "toggleChat",
                            "identify",
                            "hide",
                            "on",
                            "shutdown",
                        ],
                        n = function () {
                            var e = i[d];
                            window.MessageBirdChatWidget[e] = function () {
                                for (
                                    var t = arguments.length,
                                        i = new Array(t),
                                        n = 0;
                                    n < t;
                                    n++
                                )
                                    i[n] = arguments[n];
                                window.MessageBirdChatWidget.queue.push([
                                    [e, i],
                                ]);
                            };
                        },
                        d = 0;
                    d < i.length;
                    d++
                )
                    n();
                var a =
                        (null === (e = window) ||
                        void 0 === e ||
                        null === (t = e.MessageBirdChatWidgetSettings) ||
                        void 0 === t
                            ? void 0
                            : t.widgetId) || "",
                    o = function () {
                        var e,
                            t = document.createElement("script");
                        t.type = "text/javascript";
                        t.src =
                            "https://livechat.messagebird.com/bootstrap.js?widgetId=".concat(
                                a
                            );
                        t.async = !0;
                        t.id = "live-chat-widget-script";
                        var i = document.getElementsByTagName("script")[0];
                        null == i ||
                            null === (e = i.parentNode) ||
                            void 0 === e ||
                            e.insertBefore(t, i);
                    };
                "complete" === document.readyState
                    ? o()
                    : window.attachEvent
                    ? window.attachEvent("onload", o)
                    : window.addEventListener("load", o, !1);
            }
        })();
    }, []);
    return (
        <div className="bg-general">
            <Provider {...options} template={AlertTemplate}>
                <UserProvider>
                    <NetworkMonitor />
                    <Router>
                        <Suspense fallback={<FallBack />}>
                            <Switch>
                                <Route exact path="/login" component={Login} />
                                <Route
                                    exact
                                    path="/loan-offer"
                                    component={AcceptLoanApproval}
                                />
                                <Route
                                    exact
                                    path="/register"
                                    component={Register}
                                />
                                <Route
                                    exact
                                    path="/verify/email"
                                    component={lazy(() =>
                                        import("./pages/auth/verify_email")
                                    )}
                                />
                                <Route
                                    exact
                                    path="/password/forgot"
                                    component={lazy(() =>
                                        import("./pages/auth/forgot_password")
                                    )}
                                />
                                <Route
                                    exact
                                    path="/password/reset"
                                    component={lazy(() =>
                                        import("./pages/auth/password_reset")
                                    )}
                                />
                                <Route
                                    exact
                                    path="/social/:trx_ref"
                                    component={lazy(() =>
                                        import(
                                            "./pages/external_pay/social_pay"
                                        )
                                    )}
                                />
                                <Route
                                    exact
                                    path={"/group_pay/accept"}
                                    component={lazy(() =>
                                        import("./pages/external_pay/group_pay")
                                    )}
                                />

                                <Route
                                    exact
                                    path={"/payment/request/:ref"}
                                    component={lazy(() =>
                                        import("./pages/payment_request/index")
                                    )}
                                />
                                <Route
                                    exact
                                    path={[
                                        ...dashboardRoutes.map((v) => v.path),
                                        "/",
                                    ]}
                                    component={MainPage}
                                />
                                <Route
                                    exact
                                    path="/auth/google"
                                    component={LoginGoogle}
                                />
                                <Route
                                    exact
                                    path="/store/:name"
                                    component={lazy(() =>
                                        import("./pages/shop/storeProducts")
                                    )}
                                />
                                <Route
                                    component={lazy(() =>
                                        import("./pages/pageNotFound")
                                    )}
                                />
                            </Switch>
                        </Suspense>
                    </Router>
                </UserProvider>
            </Provider>
            <div></div>
        </div>
    );
}

export default App;
