import React, { lazy } from "react";

import { useAlert } from "react-alert";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";

import { DashboardBody } from "../components/dashboard_body";
import { isLoggedIn } from "../utils/helpers";
import { SetUp } from "./dashboard/setup";

const Dashboard = lazy(() => import("./dashboard/index"));
const Activities = lazy(() => import("./activities"));
const Wallet = lazy(() => import("./wallet"));
const MakePayment = lazy(() => import("./make-payment/make_payment"));

const SupportTicket = lazy(() => import("./support/support_ticket"));
const SupportDetail = lazy(() => import("./support/support_detail"));
const AddSupportTicket = lazy(() => import("./support/add_support_ticket"));

const Settings = lazy(() => import("./settings"));
const WhereToBuy = lazy(() => import("./shop/all-products"));
const MerchantProducts = lazy(() => import("./shop/products"));
const BuyProducts = lazy(() => import("./shop/buy"));
const PaymentPage = lazy(() => import("./pay/pay"));
const NewEnjoyNow = lazy(() => import("./checkout/newEnjoyNow.js"));
const ConfirmEnjoyNow = lazy(() => import("./checkout/confirmEnjoyNow"));
const FinalConfirmEnjoyNow = lazy(() => import("./checkout/finalConfirmation"));
const PayConfirmEnjoyNow = lazy(() => import("./checkout/payInitial"));
const ReviewLoanApplication = lazy(() =>
    import("./checkout/reviewLoanApplication")
);
const AddBvn = lazy(() => import("./checkout/addbvn"));
const AddEmployment = lazy(() => import("./checkout/addEmployment"));
const orders = lazy(() => import("./payinfours"));
const PayIn4Bank = lazy(() => import("./payin4bank"));
const products = lazy(() => import("./products"));

const CreditLimit = lazy(() => import("./credit-limit"));
const ApplyCreditLimit = lazy(() => import("./credit-limit/apply"));
const ManageAllCreditLoans = lazy(() =>
    import("./credit-limit/manageAllLoans")
);
const PaymentHistory = lazy(() => import("./credit-limit/history"));
const ManageOneCreditLoan = lazy(() => import("./credit-limit/manageOneLoan"));
const CreditSetupLoan = lazy(() => import("./credit-limit/setupLoan"));
const AcceptCreditLimit = lazy(() =>
    import("./credit-limit/apply-steps/acceptAmount")
);
const UpdateProfilePage = lazy(() => import("./complete-profile"));

const WalletCheckout = lazy(() =>
    import("./checkout/wallet/walletCheckoutOne")
);
const WalletCheckoutConfirm = lazy(() =>
    import("./checkout/wallet/walletCheckoutConfirm")
);
const WalletCheckoutReviewLoan = lazy(() =>
    import("./checkout/wallet/walletCheckoutReviewLoan")
);
const WalletCheckoutFinal = lazy(() =>
    import("./checkout/wallet/finalConfirmation")
);

export const dashboardRoutes = [
    { path: "/dashboard", component: Dashboard },
    {
        path: "/activities",
        component: Activities,
    },
    {
        path: "/wallet",
        component: Wallet,
    },
    {
        path: "/payments",
        component: MakePayment,
    },

    {
        path: "/settings",
        component: Settings,
    },
    {
        path: "/support",
        component: SupportTicket,
    },
    {
        path: "/support/detail/:support_id",
        component: SupportDetail,
    },
    {
        path: "/support/create",
        component: AddSupportTicket,
    },
    {
        path: "/where-to-buy",
        component: WhereToBuy,
    },
    {
        path: "/orders",
        component: orders,
    },
    {
        path: "/order/bank-statement",
        component: PayIn4Bank,
    },

    {
        path: "/credit-limit",
        component: CreditLimit,
    },
    {
        path: "/credit-limit/accept/:id",
        component: AcceptCreditLimit,
    },
    {
        path: "/credit-limit/history",
        component: PaymentHistory,
    },
    {
        path: "/credit-limit/manage",
        component: ManageAllCreditLoans,
    },
    {
        path: "/credit-limit/manage/:id",
        component: ManageOneCreditLoan,
    },
    {
        path: "/credit-limit/setup-loan/:id",
        component: CreditSetupLoan,
    },
    {
        path: "/credit-limit/:id",
        component: ApplyCreditLimit,
    },
    {
        path: "/products",
        component: products,
    },
    {
        path: "/profile",
        component: UpdateProfilePage,
    },
    { path: "/merchant/:id/products", component: MerchantProducts },
    {
        path: "/buy/:product",
        component: BuyProducts,
    },
    {
        path: "/buy/enjoy-now/:product",
        component: NewEnjoyNow,
    },
    {
        path: "/wallet/credit-wallet/",
        component: WalletCheckout,
    },
    {
        path: "/wallet/confirm/",
        component: WalletCheckoutConfirm,
    },
    {
        path: "/wallet-review-loan/",
        component: WalletCheckoutReviewLoan,
    },
    {
        path: "/wallet/final-confirm/",
        component: WalletCheckoutFinal,
    },
    {
        path: "/buy/enjoy-now/confirm/:product",
        component: ConfirmEnjoyNow,
    },
    {
        path: "/buy/enjoy-now/final-confirm/:product",
        component: FinalConfirmEnjoyNow,
    },
    {
        path: "/buy/enjoy-now/pay-confirm/:prediction_id",
        component: PayConfirmEnjoyNow,
    },
    {
        path: "/buy/enjoy-now/add-bvn/:product",
        component: AddBvn,
    },
    {
        path: "/buy/enjoy-now/employment/:product",
        component: AddEmployment,
    },
    {
        path: "/buy/enjoy-now/review-loan/:product",
        component: ReviewLoanApplication,
    },
    {
        path: "/pay/:payment_id",
        component: PaymentPage,
    },
    {
        path: "/payment/requests",
        component: lazy(() => import("./payment_request/view-requests")),
    },
    {
        path: "/enjoy-now",
        component: lazy(() => import("./enjoy_now/enjoy_now_home")),
    },
    {
        path: "/enjoy-now/prequalify",
        component: lazy(() => import("./enjoy_now/enjoy_now_prequalify")),
    },
    {
        path: "/enjoy-now/requests",
        component: lazy(() => import("./enjoy_now/enjoy_now_credit_requests")),
    },
    {
        path: "/enjoy-now/:id/apply",
        component: lazy(() => import("./enjoy_now/enjoy_now_application")),
    },
    {
        path: "/enjoy-now/apply/:id",
        component: lazy(() => import("./enjoy_now/enjoy_now_apply")),
    },
];

export function MainPage() {
    const history = useHistory();
    const alert = useAlert();
    if (!isLoggedIn()) {
        localStorage.setItem(
            "redirect_intended",
            history.location.pathname + history.location.search
        );
        alert.info("Please login to proceed");
        return <Redirect to={"/login"} />;
    }
    return (
        <Switch>
            <Route exact path="/" component={SetUp} />
            <Route
                path={dashboardRoutes.map((v) => v.path)}
                component={DashboardRoute}
            />
        </Switch>
    );
}

const DashboardRoute = () => (
    <DashboardBody>
        <Switch>
            {dashboardRoutes.map((dR) => (
                <Route
                    key={dR.path}
                    path={dR.path}
                    exact
                    component={dR.component}
                />
            ))}
        </Switch>
    </DashboardBody>
);
