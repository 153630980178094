import React, { useContext, useEffect } from "react";

import { useAlert } from "react-alert";
import { Link, Redirect, useHistory } from "react-router-dom";

import Loader from "../../components/loader";
import { UserContext } from "../../contexts/user_context";
import {
    useApiCall,
    useBaseFetch,
    useProductCategories,
} from "../../hooks/fetch_hooks";
import { useObjectData } from "../../hooks/test_hooks";
import {
    getEmploymentStatuses,
    getIndustries,
    getOptions,
    updateUser,
} from "../../utils/api";

export function SetUp() {
    const { user, fetchUserDetails } = useContext(UserContext);
    const history = useHistory();
    const callApi = useApiCall();
    const alert = useAlert();
    const state = useObjectData({
        count: 0,
        steps_completed: false,
        category_selection: [],
        employment_status: null,
        industry: null,
        options: [],
    });

    const handleCategorySelection = (category) => {
        const removeIndex = state.get.category_selection.findIndex(
            (v) => v === category.id
        );
        let newValue = [];
        if (removeIndex < 0) {
            newValue = [...state.get.category_selection, category.id];
        } else {
            newValue = state.get.category_selection.filter(
                (v, i) => i !== removeIndex
            );
        }
        state.set({ category_selection: Array.from(new Set(newValue)) });
    };
    const handleOptionSelection = (option) => {
        const removeIndex = state.get.options.findIndex((v) => v === option.id);
        let newValue = [];
        if (removeIndex < 0) {
            newValue = [...state.get.options, option.id];
        } else {
            newValue = state.get.options.filter((v, i) => i !== removeIndex);
        }
        state.set({ options: Array.from(new Set(newValue)) });
    };
    const handleEmploymentStatusChange = (employment_status) => {
        state.set({ employment_status: employment_status.id });
    };
    const handleIndustryChange = (industry) => {
        state.set({ industry: industry.id });
    };
    const updateProfile = () => {
        const data = {
            interests: state.get.category_selection,
            industry: state.get.industry,
            employment_status: state.get.employment_status,
            options: state.get.options,
        };
        callApi.callApi(
            () => updateUser(data),
            (data) => {
                // console.log(data);
                alert.success(data.message);
                fetchUserDetails();
                history.push("/dashboard");
            },
            (error) => {
                console.log(error);
            }
        );
    };
    //if user has not loaded dont display the page so they wont have an idea that they are here
    if (!user?.id) {
        fetchUserDetails();
        return (
            <div>
                <Loader />
            </div>
        );
    }
    //check if user has passed through this step before and if yes push to dashboard
    else if (user.industry_id || user.employment_status_id) {
        history.push("/dashboard");
    }

    const steps = [
        <ProductCategories
            selection={state.get.category_selection}
            handleSelection={handleCategorySelection}
        />,
        <Industries
            selection={state.get.industry}
            handleSelection={handleIndustryChange}
        />,
        <EmploymentStatus
            selection={state.get.employment_status}
            handleSelection={handleEmploymentStatusChange}
        />,
    ];
    return (
        <section className="min-h-screen bg-white">
            {callApi.loading && <Loader />}
            <header className="flex justify-center py-6 shadow">
                <img
                    className="h-6"
                    alt=""
                    src={require("../../assets/odiopay-logo-navy-blue.svg")}
                />
            </header>
            {state.get.count < 3 ? (
                <main className="flex flex-col items-center">
                    <h5 className="text-2xl font-semibold text-k2-indigo mt-10">
                        Help us get you set up faster
                    </h5>
                    <p className="text-k-gray text-sm mt-8 max-w-md text-justify">
                        We will use the information provided to personalize your
                        Odiopay experience and match you with great offers from
                        your favourite retailers and brands.
                    </p>
                    <div className="my-8">
                        <Counter count={state.get.count + 1} total={3} />
                    </div>
                    {steps[state.get.count]}
                    <div className="h-40" />
                    <div className="fixed bottom-0 left-0 right-0 bg-white border-t-2 py-5 pr-12 mt-6 flex-1 w-full flex justify-end items-center text-center space-x-5">
                        <Link to={"/dashboard"} className="text-k-indigo">
                            Skip for now
                        </Link>
                        <button
                            onClick={() =>
                                state.set({ count: state.get.count + 1 })
                            }
                            className="px-8 py-3 bg-primary text-white"
                        >
                            Next Step
                        </button>
                    </div>
                </main>
            ) : (
                <main className="flex flex-col items-center">
                    <h5 className="text-2xl font-semibold text-k2-indigo mt-10">
                        Lastly, What would you like to do with Odiopay
                    </h5>
                    <p className="text-k-gray text-sm mt-8 max-w-md text-justify">
                        Choose options that applies to you
                    </p>
                    <div>
                        <Options
                            selection={state.get.options}
                            handleSelection={handleOptionSelection}
                        />
                    </div>
                    <div className="h-40" />
                    <div className="fixed bottom-0 bg-white border-t-2 py-5 pr-12 mt-6 flex-1 w-full flex justify-center items-center text-center space-x-5">
                        <button
                            onClick={updateProfile}
                            className="px-12 text-sm py-3 bg-primary text-white"
                        >
                            Continue
                        </button>
                    </div>
                </main>
            )}
        </section>
    );
}

function Counter({ count, total }) {
    return (
        <div className="text-center">
            <div className="text-2xl text-k6-gray mb-5">{`${count}/${total}`}</div>
            <div className="flex justify-center items-center space-x-5">
                {Array(total)
                    .fill(0)
                    .map((v, index) => (
                        <div
                            className={`border w-24 ${
                                count === index + 1
                                    ? "border-k3-blue"
                                    : "border-k6-gray"
                            }`}
                        />
                    ))}
            </div>
        </div>
    );
}

function ProductCategories({ selection, handleSelection }) {
    const [cat] = useProductCategories();
    const categories = cat.data || [];

    return (
        <div>
            <h6 className="text-3xl font-semibold text-k2-indigo text-center">
                What product categories are you interested in?
            </h6>
            <p className="text-k-gray text-sm mt-2 mb-6 text-center">
                Choose the options that applies to you
            </p>
            <div className="flex justify-center whitespace-no-wrap flex-wrap">
                {categories.map((category) => {
                    const active =
                        selection.findIndex((v) => v === category.id) >= 0;
                    return (
                        <div
                            key={category.id}
                            onClick={() => handleSelection(category)}
                            className={
                                (active ? "bg-k4-blue bg-opacity-25" : "") +
                                " cursor-pointer flex items-center m-4 text-k-gray text-sm border border-k2-gray rounded-sm px-5 py-4"
                            }
                        >
                            <span
                                className={
                                    (active
                                        ? "border-white"
                                        : "border-k2-gray") +
                                    " border font-bold mr-2 pt-1 inline-flex justify-center items-center h-5 w-5  rounded-full"
                                }
                            >
                                {active && (
                                    <span className="text-green-400 font-black">
                                        {" "}
                                        ✓
                                    </span>
                                )}
                            </span>
                            <span>{category.name}</span>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

function Industries({ selection, handleSelection }) {
    const [data] = useBaseFetch("", [], getIndustries);

    return (
        <div>
            <h6 className="text-3xl font-semibold text-k2-indigo text-center">
                What's your industry?
            </h6>
            <p className="text-k-gray text-sm mt-2 mb-6 text-center">
                Choose the option that applies to you
            </p>
            <div className="flex justify-center whitespace-no-wrap flex-wrap">
                {data &&
                    data.map((category) => {
                        const active = selection === category.id;
                        return (
                            <div
                                key={category.id}
                                onClick={() => handleSelection(category)}
                                className={
                                    (active ? "bg-k4-blue bg-opacity-25" : "") +
                                    " cursor-pointer flex items-center m-4 text-k-gray text-sm border border-k2-gray rounded-sm px-5 py-4"
                                }
                            >
                                <span
                                    className={
                                        (active
                                            ? "border-white"
                                            : "border-k2-gray") +
                                        " border font-bold mr-2 pt-1 inline-flex justify-center items-center h-5 w-5  rounded-full"
                                    }
                                >
                                    {active && (
                                        <span className="text-green-400 font-black">
                                            {" "}
                                            ✓
                                        </span>
                                    )}
                                </span>
                                <span>{category.name}</span>
                            </div>
                        );
                    })}
            </div>
        </div>
    );
}

function EmploymentStatus({ selection, handleSelection }) {
    const [data] = useBaseFetch("", [], getEmploymentStatuses);

    return (
        <div>
            <h6 className="text-3xl font-semibold text-k2-indigo text-center">
                What's your employment status?
            </h6>
            <p className="text-k-gray text-sm mt-2 mb-6 text-center">
                Choose the option that applies to you
            </p>
            <div className="flex justify-center whitespace-no-wrap flex-wrap">
                {data &&
                    data.map((category) => {
                        const active = selection === category.id;
                        return (
                            <div
                                key={category.id}
                                onClick={() => handleSelection(category)}
                                className={
                                    (active ? "bg-k4-blue bg-opacity-25" : "") +
                                    " cursor-pointer flex items-center m-4 text-k-gray text-sm border border-k2-gray rounded-sm px-5 py-4"
                                }
                            >
                                <span
                                    className={
                                        (active
                                            ? "border-white"
                                            : "border-k2-gray") +
                                        " border font-bold mr-2 pt-1 inline-flex justify-center items-center h-5 w-5  rounded-full"
                                    }
                                >
                                    {active && (
                                        <span className="text-green-400 font-black">
                                            {" "}
                                            ✓
                                        </span>
                                    )}
                                </span>
                                <span>{category.name}</span>
                            </div>
                        );
                    })}
            </div>
        </div>
    );
}

function Options({ selection, handleSelection }) {
    const [data] = useBaseFetch("", [], getOptions);

    return (
        <div className="flex flex-col justify-center">
            {data.map((category) => {
                const active =
                    selection.findIndex((v) => v === category.id) >= 0;
                return (
                    <div
                        key={category.id}
                        onClick={() => handleSelection(category)}
                        className={
                            (active ? "bg-k4-blue bg-opacity-25" : "") +
                            " cursor-pointer flex items-center m-4 max-w-lg text-k-gray text-sm border border-k2-gray rounded-sm px-5 py-4"
                        }
                    >
                        <span
                            className={
                                (active ? "border-white" : "border-k2-gray") +
                                " border font-bold mr-3 pt-1 inline-flex justify-center items-center h-5 w-5  rounded-full"
                            }
                        >
                            {active && (
                                <span className="text-green-400 font-black">
                                    {" "}
                                    ✓{" "}
                                </span>
                            )}
                        </span>
                        <div className="flex-1">
                            <h6 className="font-black text-sm mb-1">
                                {category.name}
                            </h6>
                            <p>{category.description}</p>
                        </div>
                    </div>
                );
            })}
        </div>
    );
}
