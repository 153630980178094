import React from "react";
import { SideBar } from "./sidebar";
import { Header } from "./header";
import { Onboarding } from "./onboarding";

export function DashboardBody({ children }) {
    return (
        <div className="flex flex-col-reverse md:flex-row justify-start">
            <SideBar />
            <div className="bg-white flex-1 md:max-h-screen  overflow-hidden pb-20">
                <Header />
                {/* temporarily disabled */}
                {/* <Onboarding/> */}
                <section className="flex-1 justify-start h-full overflow-y-auto overflow-x-hidden max-w-full bg-white">
                    {children}
                </section>
            </div>
        </div>
    );
}
