const prodConfig = {
    API_URL: "https://api.odiopay.com/api/v1",
    BASE_URL: "https://api.odiopay.com/api/v1",
    // PAYSTACK_PK: "pk_test_342641fe81040f4ddcf0030f6499bbe29dea8d24",
    // PAYSTACK_PK: "pk_live_f114d084406e1b974b0623444d8f4672c3cb7825n",
    PAYSTACK_PK: "pk_live_f114d084406e1b974b0623444d8f4672c3cb7825",
    loanxUrl: "https://loanx.odiopay.com/api/v1",
    // okra: {
    //     env: "production",
    //     key: "306f2df1-5ecb-5d4e-ac4d-de5ab7224017",
    //     token: "5eeb30f1ac63db0684c8c1dc",
    // },
    okra: {
        env: "development",
        key: async (ALL_KEYS) => {
            return (await ALL_KEYS)?.data?.data?.data?.okra_secret_key;
        },
        token: async (ALL_KEYS) => {
            return (await ALL_KEYS)?.data?.data?.data?.okra_secret_token;
        },
    },
    mono: {
        public_key: async (ALL_KEYS) => {
            return (await ALL_KEYS)?.data?.data?.data?.mono_public_key;
        },
    },
};
const devConfig = {
    // API_URL: "https://fef4-196-189-50-199.ngrok.io",
    // BASE_URL: "https://fef4-196-189-50-199.ngrok.io",

    // API_URL: "https://api.odiopay.com/api/v1",
    // BASE_URL: "https://api.odiopay.com/api/v1",

    API_URL: "https://odiopay-staging-be-gdja.onrender.com/api/v1",
    BASE_URL: "https://odiopay-staging-be-gdja.onrender.com/api/v1",
    // PAYSTACK_PK: "pk_test_342641fe81040f4ddcf0030f6499bbe29dea8d24",
    // PAYSTACK_PK: "pk_live_f114d084406e1b974b0623444d8f4672c3cb7825n",
    PAYSTACK_PK: "pk_test_5607093b223eeda8d939bd15b22796cd8d42076c",
    loanxUrl: "https://loanx-be-staging.odiopay.com/api/v1",
    // PAYSTACK_PK: "pk_test_2a2a433e070e492f6d84c065f590a1a1314931c8",
    // okra: {
    //     env: "production",
    //     key: "306f2df1-5ecb-5d4e-ac4d-de5ab7224017",
    //     token: "5eeb30f1ac63db0684c8c1dc",
    // },
    // okra: {
    //     //this is dev
    //     env: "development",
    //     key: "7e5f00a8-89cd-5620-9c69-cfcb41eb7d99",
    //     token: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI1ZWViMzBmMWFjNjNkYjA2ODRjOGMxZGMiLCJpYXQiOjE1OTI0NzE3OTN9.qpP9tYV5N_tL3aRvmiFvwq0PRwgM_S-E_waDGd09Yts",
    // },
    okra: {
        //this is prod
        env: "production",
        key: async (ALL_KEYS) => {
            return (await ALL_KEYS)?.data?.data?.data?.okra_secret_key;
        },
        // token: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI1ZWViMzBmMWFjNjNkYjA2ODRjOGMxZGMiLCJpYXQiOjE1OTc0MTY5NjZ9.nV3Vf4_XR1FJgjtbbNIY5Q658iIHJgLU3sYMafYP9QU",
        token: async (ALL_KEYS) => {
            return (await ALL_KEYS)?.data?.data?.data?.okra_secret_token;
        },
    },
    mono: {
        public_key: async (ALL_KEYS) => {
            return (await ALL_KEYS)?.data?.data?.data?.mono_public_key;
        },
    },
    // okra: {
    //   env: "production-sandbox",
    //   key: "7e5f00a8-89cd-5620-9c69-cfcb41eb7d99",
    //   token: "5eeb30f1ac63db0684c8c1dc"
    // }
};

const generalConfig = {
    PASSWORD_PATTERN:
        "^(?=.*[A-Za-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*#?&])[A-Za-z\\d@$!%*#?&\\.]{8,}$",
    TOKEN_TYPE: "ODAD_Tokentype",
    TOKEN_NAME: "ODAD_Token",
};
export const config = {
    ...generalConfig,
    ...(process.env.REACT_APP_ENV === "production" ? prodConfig : devConfig),
    // ...(false ? prodConfig : devConfig),
};

export const paystackConfig = {
    ptk: "sk_live_5d0b63acd75d5d104be091f541d32777c33beeda",
    // ptk: "sk_test_b2d3e9c82c0c03f34b0661ad09a1522b28e378c4",
    // ptk: "Sk_live_665c4ff26532bc1fb0ba3b5685e1e664c2cde21b",
    // pbk: "Pk_live_f114d084406e1b974b0623444d8f4672c3cb7825n",
    pbk: "pk_live_f114d084406e1b974b0623444d8f4672c3cb7825",
    // pbk: "pk_test_342641fe81040f4ddcf0030f6499bbe29dea8d24",
    // ptk: "sk_test_e132c5844e39a30cee29d4988383ac3e854b8d6a",

    url: "https://api.paystack.co",
};

export const repaymentConfig = {
    url: "https://cardchargeapi.herokuapp.com/api",
};

export const directDebit = {
    url: " https://loanx.odiopay.com/api/v1",
};
export const PREDICTION_TYPES = {
    credit_limit: "CREDIT_LIMIT",
    from_credit_limit: "ENJOY_NOW_REQUEST",
    payin4: "PAY_IN_4",
    wallet: "WALLET",
};
