import dayjs from "dayjs";

import { api_auth, api_normal } from "./helpers";

export function login({ email, password }) {
    const req_body = {
        email: email.toLowerCase(),
        password: password,
    };
    return api_normal().post("/login", req_body);
}

export function register({
    first_name,
    last_name,
    email,
    password,
    product_id,
    phone_number,
}) {
    const req_body = {
        email,
        first_name,
        last_name,
        password,
        product_id,
        confirm_password: password,
        phone_number,
        type: "customer",
    };
    return api_normal().post("/register", req_body);
}

export function initiatePasswordReset(email) {
    return api_normal().post("/reset_password/initiate", {
        email: email,
    });
}

export function initiatePasswordChange(email, password, code) {
    return api_normal().post("reset_password/verify", {
        email,
        password,
        confirm_password: password,
        token: code,
    });
}

export function verifyEmail(email, code) {
    return api_normal().post("/verify", {
        email: email,
        verification_code: code,
    });
}

export function fetchAllTransactions() {
    return api_auth().get("/transactions?orderBy=DESC");
}

export function recentTransactions(page = 5) {
    return api_auth().get(`/transactions?paginate=${page}&orderBy=DESC`);
}

export function resendVerification(email) {
    return api_normal().post("/verify/resend", {
        email,
    });
}

export function getGoogleURL() {
    return api_normal().get(`/auth/google/url`);
}

export function setIntroPageViewed(id) {
    return api_auth().patch(`/setIsViewedStatus`, {
        userId: id,
        isIntroViewed: true,
    });
}

export function uploadMonoStatementCredit(code) {
    return api_auth().post(`/creditAmount/monoUploadStatement`, { code });
}

export function walletTransactions(page = 4) {
    return api_auth().get(`/wallet-transactions?paginate=${page}`);
}

export function fetchTransactionPaymentHistory(ref, page = 4, desc) {
    return api_auth().get(
        `/payments?paginate=${page}&transaction_ref=${ref}&orderBy=${
            desc ? "DESC" : "ASC"
        }`
    );
}

export function fetchPendingTransaction() {
    return api_auth().get("/transactions?status=pending&orderBy=DESC");
}

export function fetchProgressTransaction() {
    return api_auth().get("/transactions?status=progress&orderBy=DESC");
}

export function fetchPendingAndProgress() {
    return api_auth().get(
        "/transactions?status[0]=progress&status[1]=pending&orderBy=DESC"
    );
}

export function getUser() {
    return api_auth().get("/get-user");
}

export function getCardDetails() {
    return api_auth().get("/card-details");
}

export function saveCard(ref) {
    if (process.env.REACT_APP_ENV === "production") {
        return api_auth().post(`/save-card/${ref}`);
    } else {
        return api_auth().post(`/save-card/${ref}?env=test`);
    }
}
export function topupWallet(ref) {
    return api_auth().post("/topup/card/" + ref);
}
export function topupWalletWithSavedCard(card_detail_id, amount) {
    return api_auth().post("/topup/saved-card", {
        card_detail_id,
        amount,
    });
}
export function getSupportTickets() {
    return api_auth().get("/supports?orderBy=DESC");
}

export function getSupportDetails(id) {
    return api_auth().get("/supports/" + id);
}

export function getSupportReplies(id) {
    return api_auth().get(`/supports/${id}/replies?paginate=100&orderBy=DESC`);
}

export function replySupportTicket(id, message) {
    return api_auth().post(`/supports/${id}`, {
        message,
    });
}

export function closeSupportTicket(id) {
    return api_auth().patch(`/supports/${id}`, {
        confirm: 1,
    });
}

export function fetchTotalPaymentAggregate() {
    return api_auth().get(
        "/payments/aggregate?fields=count,sum_service_charge,sum_amount"
    );
}

export function fetchBefore30DaysPaymentAggregate() {
    const end_date = dayjs().subtract(30, "days").format("YYYY-MM-DD");
    return api_auth().get(
        `/payments/aggregate?fields=count,sum_service_charge,sum_amount&end_date=${end_date}`
    );
}

export function fetchDailyPaymentAggregate() {
    const start_date = dayjs().format("YYYY-MM-DD");
    return api_auth().get(
        `/payments/aggregate?fields=count,sum_service_charge,sum_amount&start_date=${start_date}&end_date=${start_date}`
    );
}

export function fetchYesterdayPaymentAggregate() {
    const start_date = dayjs().subtract(1, "day").format("YYYY-MM-DD");
    return api_auth().get(
        `/payments/aggregate?fields=count,sum_service_charge,sum_amount&start_date=${start_date}&end_date=${start_date}`
    );
}

export function fetchTotalPendingPayment() {
    return api_auth().get(
        "/payments/aggregate?fields=count,sum_service_charge,sum_amount&status=pending"
    );
}

export function fetchTotalReceivedPayment() {
    return api_auth().get(
        "/payments/aggregate?fields=count,sum_service_charge,sum_amount&status=successful"
    );
}

export function fetchMonthlyTransactionCount() {
    const start_date = dayjs().subtract(30, "days").format("YYYY-MM-DD");
    const end_date = dayjs().format("YYYY-MM-DD");
    return api_auth().get(
        `/transactions/count?start_date=${start_date}&end_date=${end_date}`
    );
}

export function fetchMonthlyRefunds() {
    const start_date = dayjs().subtract(30, "days").format("YYYY-MM-DD");
    const end_date = dayjs().format("YYYY-MM-DD");
    return api_auth().get(
        `/transactions/count?status=payback&start_date=${start_date}&end_date=${end_date}`
    );
}

export function updateUserProfile(data) {
    return api_auth().patch("/update-user-info", data);
}

export function changePassword(old_password, new_password) {
    return api_auth().patch("change_password", {
        old_password: old_password,
        password: new_password,
        confirm_password: new_password,
    });
}

export function uploadPassport(file) {
    const formData = new FormData();
    formData.append("passport", file);
    return api_auth().post("/upload-passport", formData);
}

export function createSupportTicket({
    title,
    message,
    priority,
    transaction_ref,
}) {
    return api_auth().post("/support", {
        title,
        message,
        priority,
        transaction_ref,
    });
}

export function getOngoingTransactionReports() {
    return api_auth().get("/ongoing-transactions/report");
}

export function getMerchantCategories() {
    return api_auth().get("/merchants/categories");
}

export function getProductCategories() {
    return api_auth().get("/products/categories");
}

export function getEmploymentStatuses() {
    return api_auth().get("/employment-statuses");
}

export function getInterests() {
    return api_auth().get("/interests");
}
export function getOptions() {
    return api_auth().get("/options");
}
export function getIndustries() {
    return api_auth().get("/industries");
}

export function updateUser(data) {
    return api_auth().patch("/update-user", data);
}

export function verifyBVN(bvn, dob) {
    return api_auth().post("/identity", { dob, bvn });
}

export function generateNextRepayment(tref, data) {
    return api_auth().post("/repayment/" + tref, data);
}

export function getMerchantProducts(merchant_id) {
    return api_auth().get(
        `/products/index?merchant_id=${merchant_id}& paginate=15&orderBy=DESC`
    );
}
export function getStoreProducts(name) {
    return api_auth().get(
        `/getStoreProducts?storeName=${name}&paginate=15&orderBy=DESC`
    );
}

export function getAllProducts() {
    return api_auth().get(`/products/index?paginate=16&orderBy=DESC`);
}

export function getProductDetails(product_id) {
    return api_auth().get("/get-product/" + product_id);
}

export function getProductRules(product_id, quantity) {
    return api_auth().get(
        `/product/${product_id}/get_rules?quantity=${quantity}`
    );
}

export function initializeTransaction(data) {
    return api_auth().post(`/transaction/initialize?mode=live`, data);
}

export function activateCreditLimit(loanId) {
    return api_auth().patch(`/activate-credit-limit`, {
        loan_id: loanId,
    });
}

export function setupDirectDebit(loanId) {
    return api_auth().get(`/loanX/changeToDirectDebit?prediction_id=${loanId}`);
}

export function setRemitaValidatedOnPrediction(prediction_id) {
    return api_auth().patch(`/setRemitaStatus/${prediction_id}`, {
        has_remita: true,
    });
}

export function setupDirectDebitLoanx(card_id, loan_id) {
    return api_auth().post(`/loanX/setUpDirectDebit`, {
        card_id,
        loanx_loan_id: loan_id,
    });
}
export function repaymentFromReference(loanId, reference, changeCard = true) {
    return api_auth().post(`/repayment/repaymentFromReference`, {
        reference,
        loanX_loan_id: loanId,
        changeCard,
    });
}

export function verifyAddCardLoanx(reference) {
    return api_auth().get(`/repayment/verifyAddCard?reference=${reference}`);
}

export function makePaymentSavedCard(card_id, amount) {
    return api_auth().post(`/repayment/payFromSavedCard`, { card_id, amount });
}

export function addAccountDetails(
    bank_id,
    bank_accountNumber,
    bank_account_name
) {
    return api_auth().post(`/bank-details`, {
        bank_id: bank_id,
        bank_account_number: bank_accountNumber,
        bank_account_name: bank_account_name,
    });
}
/**
 * @returns {
 * {
 *  data:{
 *    mono_public_key:string,
 *    mono_private_key:string,
 *    okra_secret_key:string,
 *    okra_secret_token:string
 *  }
 * }
 * }
 */
export function getAllKeys() {
    return api_auth().get("/loanX/keys");
}

export function getAllProducts2(url, search, urlMethod) {
    if (urlMethod === "GET") {
        return api_auth().get(url);
    }
    // return api.get(url || "/products/index?paginate=18&orderBy=DESC");
    if (search) {
        return api_auth().post(url || "/products/globalSearch", {
            keyword: search,
        });
    }
    return api_auth().post(url || "/products/globalSearch");
}

//credit limits
export function getAllCreditLimits() {
    return api_auth().get("/creditAmount/GetMyCredits");
}
export function applyForCreditLimit() {
    return api_auth().post("/creditAmount/ApplyCreditLimit");
}
export function uploadBankStatement(data) {
    return api_auth().post("/creditAmount/UploadBankStatement", data);
}

export function addBankStatementToCreditLimit(
    credit_limit_id,
    bank_statement_id
) {
    return api_auth().post("/creditAmount/AddBankStatementToCreditLimit", {
        credit_limit_id,
        bank_statement_id,
    });
}
export function addCardToCreditLimit(credit_limit_id, card_id) {
    return api_auth().post("/creditAmount/AddCardToCreditLimit", {
        credit_limit_id,
        card_id,
    });
}
export function getCreditAmount() {
    return api_auth().post("/creditAmount/GetCreditAmount");
}
export function acceptCreditLimit(credit_limit_id) {
    return api_auth().post("/creditAmount/AcceptCreditLimit", {
        credit_limit_id,
    });
}
export function denyCreditLimit(credit_limit_id) {
    return api_auth().post("/creditAmount/DenyCreditLimit", {
        credit_limit_id,
    });
}
