import {useSingleData} from "../hooks/test_hooks";
import {useContext, useEffect} from "react";
import {UserContext} from "./user_context";

export function useDashboard(){
    return useSingleData('')

}



export function useSetDashboardTitle(title){
    const {dashboard: d} = useContext(UserContext)
    useEffect(() => {
        d.set(title)
        return () => d.set('')
    }, [])
    return d
}
