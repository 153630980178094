import axios from "axios";
import dayjs from "dayjs";

import { config } from "./constants";

export function isLoggedIn() {
    const expiry =
        new Date().getTime() <
        new Date(localStorage.getItem(config.TOKEN_NAME + "_expiry")).getTime();
    return !!localStorage.getItem(config.TOKEN_NAME) && expiry;
}

export function api_auth() {
    const instance = axios.create({
        baseURL: config.API_URL,
        headers: {
            Authorization:
                localStorage.getItem(config.TOKEN_TYPE) +
                " " +
                localStorage.getItem(config.TOKEN_NAME),
        },
    });
    instance.interceptors.response.use(undefined, (err) => {
        if (err.response) {
            if (err.response.status === 401) {
                localStorage.removeItem(config.TOKEN_NAME);
                localStorage.removeItem(config.TOKEN_NAME + "_expiry");
                localStorage.setItem(
                    "redirect_intended",
                    window.location.pathname + window.location.search
                );
                window.location = "/login";
                return;
            }
        }
        return Promise.reject(err);
    });
    return instance;
}

export const api_normal = () => {
    const instance = axios.create({
        baseURL: config.API_URL,
        headers: {
            "Content-Type": "application/json;charset=utf-8",
        },
    });
    return instance;
};

export const parseErrorToArray = (error) => {
    const arr = [];
    Object.entries(error).forEach(([k, v]) => {
        arr.push(...v);
    });
    return arr;
};

export const parseToNaira = (value) => {
    const v = value || 0;
    return (+v).toLocaleString("en-NG", {
        style: "currency",
        currency: "NGN",
        minimumFractionDigits: 0,
    });
};

export const mixPendingProgress = (pending, progress) => {
    const a = pending.data || [];
    const b = progress.data || [];

    const sorted = [...a, ...b];
    sorted.sort((c, d) => {
        return (
            dayjs(c.next_pay_date).valueOf() < dayjs(d.next_pay_date).valueOf()
        );
    });
    return {
        pending,
        progress,
        data: sorted,
    };
};

export function generateInstallmentSchedule(
    paymentAmount,
    firstPayAt,
    productAmount,
    lastPaymentDate,
    duration_count,
    durationType
) {
    const nInstallment = Math.floor(
        lastPaymentDate.diff(firstPayAt, durationType) / duration_count
    );
    // console.log('No of installments between', firstPayAt.toDate(), 'and', lastPaymentDate.toDate(), 'is', nInstallment, 'for', durationType)
    const noOfInstallment = [];
    noOfInstallment.push({
        n: 0,
        amount: paymentAmount,
        charge_date: firstPayAt,
        charge_date_formatted: firstPayAt.format("DD MMM YYYY"),
        balance: productAmount,
    });
    for (let i = 0; i < nInstallment; i++) {
        const date = dayjs(firstPayAt).add(
            duration_count * (i + 1),
            durationType
        );
        const amount_to_pay = (productAmount - paymentAmount) / nInstallment;
        noOfInstallment.push({
            n: i + 1,
            amount: amount_to_pay,
            charge_date: date,
            charge_date_formatted: date.format("DD MMM YYYY"),
            balance: productAmount - amount_to_pay * (i + 1) - paymentAmount,
        });
    }
    return noOfInstallment;
}

export const numberFormat = (num) => {
    return `${num}${
        num === 1 ? "st" : num === 2 ? "nd" : num === 3 ? "rd" : "th"
    }`;
};
export const getPaymentLink = (payment_id) => {
    return `/pay/${payment_id}`;
};

export const mergeArray = (first, second) => {
    return first.map((f, i) => ({ ...f, ...second?.[i] }));
};

export function clearAuthToken(redirect = false) {
    localStorage.removeItem(config.TOKEN_NAME);
    localStorage.removeItem(config.TOKEN_NAME + "_expiry");
    if (redirect) {
        window.location = "/";
    }
}

export function profileComplete(user) {
    // return ["phone", "home_address", "city", "gender"].every((v) => user[v]);
    if (user?.employments?.length) {
        return ["phone", "home_address", "gender", "bvn"].every((v) => user[v]);
    } else {
        return false;
    }
}
