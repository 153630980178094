import { useCallback, useEffect, useRef, useState } from "react";

import { useAlert } from "react-alert";

import {
    fetchAllTransactions,
    fetchPendingAndProgress,
    fetchPendingTransaction,
    fetchProgressTransaction,
    fetchTransactionPaymentHistory,
    getAllProducts,
    getCardDetails,
    getMerchantCategories,
    getMerchantProducts,
    getOngoingTransactionReports,
    getProductCategories,
    getProductDetails,
    getProductRules,
    getSupportDetails,
    getSupportReplies,
    getSupportTickets,
    getUser,
    recentTransactions,
    walletTransactions,
    getStoreProducts,
} from "../utils/api";
import { api_auth, api_normal } from "../utils/helpers";

export async function apiCaller(apiFunction, onSuccess, onError, onComplete) {
    try {
        const response = await apiFunction();
        if (response.data) {
            if (!!onSuccess) {
                onSuccess(response.data);
            }
        } else {
            onSuccess("");
        }
    } catch (err) {
        if (err.response) {
            console.warn(err.response.data);
            if (!!onError) {
                onError(err.response);
            }
        }
    } finally {
        if (!!onComplete) {
            onComplete();
        }
    }
}

export function useApiCall() {
    const [error, setError] = useState("");
    const [valError, setValError] = useState({});
    const [loading, setLoading] = useState(false);
    const alert = useAlert();
    const callApi = useCallback(
        /**
         *
         * @param {} apiFunction
         * @param {} successCallback
         * @param {(e:import("axios").AxiosResponse)=> Promise<any>} errorCallback
         */
        async (
            apiFunction,
            successCallback,
            errorCallback,
            showToast = true
        ) => {
            try {
                setError("");
                setValError({});
                setLoading(true);
                const response = await apiFunction();
                setLoading(false);
                if (response.data) {
                    if (!!successCallback) {
                        successCallback(response.data);
                    }
                } else {
                    successCallback("");
                }
            } catch (err) {
                setLoading(false);
                if (err.response) {
                    console.warn(err.response.data);
                    setValError(err.response.data.error || {});
                    let message = "An Error Occurred";
                    if (err.response.data.message) {
                        message = err.response.data.message;
                        setError(err.response.data.message);
                        // setLoading(true);
                    }
                    if (err?.response?.data?.error) {
                        message = Object.values(err.response.data.error).join(
                            ",\n"
                        );
                    }
                    if (showToast) alert.error(message);

                    if (!!errorCallback) {
                        errorCallback(err.response);
                    }
                }
            } finally {
            }
        },
        []
    );

    return { loading, error, valError, callApi };
}

export function useBaseFetch(
    name,
    baseInitial,
    fetchX,
    dependencies = [],
    alertN = true,
    fetchAtFirst = true
) {
    const isMounted = useRef(true);
    const firstRun = useRef(true);
    const [isLoading, showLoading] = useState(false);
    const [base, setBase] = useState(baseInitial);
    const alert = useAlert();
    useEffect(() => {
        if (!fetchAtFirst && firstRun.current) {
            return;
        }
        fetchAgain();
        return () => {
            isMounted.current = false;
        };
    }, dependencies);
    useEffect(() => {
        if (!firstRun.current) {
            firstRun.current = false;
        }
    }, []);

    async function fetchAgain() {
        try {
            showLoading(true);
            const response = await fetchX();
            if (response.data && isMounted.current) {
                setBase(response.data.data);
            }
        } catch (e) {
            // console.log(e);
            if (alertN) {
                alert.error("Unable to fetch " + name);
            }
        } finally {
            if (isMounted.current) {
                showLoading(false);
            }
        }
    }

    async function fetchNextOrPrev(url) {
        try {
            showLoading(true);
            const response = await api_auth().get(url);
            if (response.data && isMounted.current) {
                setBase(response.data.data);
            }
        } catch (e) {
            // console.log(e);
            alert.error("Unable to fetch" + name);
        } finally {
            if (isMounted.current) {
                showLoading(false);
            }
        }
    }

    return [base, isLoading, fetchAgain, fetchNextOrPrev];
}

export function useTransactions() {
    return useBaseFetch("Transactions", {}, fetchAllTransactions);
}

export function useWalletTransactions(page = 4) {
    return useBaseFetch("Wallet Transactions", {}, () =>
        walletTransactions(page)
    );
}

export function usePaymentHistory(ref, page = 4, desc = true) {
    return useBaseFetch(
        "Payment History",
        {},
        () => fetchTransactionPaymentHistory(ref, page, desc),
        [ref, desc]
    );
}

export function usePendingTransactions() {
    return useBaseFetch("Pending Transactions", {}, fetchPendingTransaction);
}

export function useProgressTransactions() {
    return useBaseFetch(
        "Transactions in progress",
        {},
        fetchProgressTransaction
    );
}

export function usePendingAndProgressTransactions() {
    return useBaseFetch("Ongoing Payments", {}, fetchPendingAndProgress);
}

export function useCustomerDetails() {
    return useBaseFetch("User Details", {}, getUser, [], true, false);
}

export function useCardDetails() {
    return useBaseFetch("Card Details", [], getCardDetails);
}

export function useSupportTickets() {
    return useBaseFetch("Support Tickets", {}, getSupportTickets);
}

export function useSupportDetails(id) {
    return useBaseFetch("Support Ticket Details", {}, () =>
        getSupportDetails(id)
    );
}

export function useSupportReplies(id) {
    return useBaseFetch("Support Ticket Replies", {}, () =>
        getSupportReplies(id)
    );
}

const calculate_increment = (now, before) => {
    const n = now || 0;
    const b = before || 0;
    if (n === 0) {
        return 0;
    } else {
        const inc = ((n - b) / b) * 100;
        return Math.round(inc);
    }
};

export function useDashboard() {
    const [ongoing, isloading, fetchAgain] = useBaseFetch(
        "Dashboard",
        {},
        getOngoingTransactionReports
    );
    const data = {
        pending: ongoing.pendingAmount || 0,
        paid: ongoing.paidAmount || 0,
        transactions: ongoing.count,
    };
    return [data, isloading];
}

export function useRecentTransactions(page = 6) {
    return useBaseFetch("Recent Transactions", {}, () =>
        recentTransactions(page)
    );
}

export function useMerchantCategories() {
    return useBaseFetch("Merchant Categories", {}, getMerchantCategories);
}

export function useProductCategories() {
    return useBaseFetch("Product Categories", {}, getProductCategories);
}

export function useMerchantProducts(id) {
    return useBaseFetch(
        "Merchant products",
        [],
        () => getMerchantProducts(id),
        [id]
    );
}
export function useStoreProducts(name) {
    return useBaseFetch(
        "Store products",
        [],
        () => getStoreProducts(encodeURI(name)),
        [name]
    );
}
export function useAllProducts() {
    return useBaseFetch("Products", [], () => getAllProducts(), []);
}

export function useProductDetails(id) {
    return useBaseFetch("Product details", {}, () => getProductDetails(id), [
        id,
    ]);
}

export function useProductRules(product_id, quantity) {
    return useBaseFetch(
        "Product rules",
        [],
        () => getProductRules(product_id, quantity),
        [product_id, quantity]
    );
}

export function useUnAuthTransactionDetails(trx_ref) {
    return useBaseFetch(
        "Transaction details",
        {},
        () => api_normal().get("/"),
        [trx_ref]
    );
}
