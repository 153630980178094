import React, { useEffect, useReducer, useState } from "react";

import { useAlert } from "react-alert";
import { Link, Redirect } from "react-router-dom";
import { Tooltip } from "react-tippy";

import Loader from "../../components/loader";
import { PasswordField } from "../../components/password_field";
import { getGoogleURL, register } from "../../utils/api";
import { config } from "../../utils/constants";
import { isLoggedIn, parseErrorToArray } from "../../utils/helpers";
import bgImage from "../../assets/ODIO-LADY.png";
function registerReducer(state, action) {
    switch (action.type) {
        case "field": {
            return {
                ...state,
                [action.field]: action.value,
            };
        }
        case "register": {
            return {
                ...state,
                is_loading: true,
                error: {},
            };
        }
        case "error": {
            return {
                ...state,
                is_loading: false,
                error: action.value,
            };
        }
        case "success": {
            return {
                ...state,
                is_loading: false,
            };
        }
        default: {
            return state;
        }
    }
}

const initialState = {
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    agree: false,
    is_loading: false,
    error: {},
};

export default function Register({ history }) {
    const [state, dispatch] = useReducer(registerReducer, initialState);
    const [hint, showHint] = useState(false);
    const {
        first_name,
        last_name,
        email,
        password,
        agree,
        is_loading,
        error,
        phone_number,
    } = state;
    const alert = useAlert();
    const [fromAd, setFromAd] = useState(false);
    const [loading, setLoading] = useState(false);
    const handleGoogle = async () => {
        try {
            setGoogleLoading(true);
            const urlData = await getGoogleURL();
            if (urlData.data) {
                window.open(urlData.data.url, "_self");
            } else {
                alert.error(urlData.message);
            }
            setGoogleLoading(false);
        } catch (e) {
            let message = "An error occurred";
            alert.error(message);
        }
    };
    const [googleLoading, setGoogleLoading] = useState(false);
    const [phoneError, setPhoneError] = useState("");
    useEffect(() => {
        const query = new URLSearchParams(history.location.search);
        const email = query.get("email");
        if (query.get("fromAd")) {
            setFromAd(true);
        }
        if (email) {
            dispatch({
                type: "field",
                field: "email",
                value: email,
            });
        }
        //if query contains redirect intended, set it to local storage
        const redirect_intended = query.get("redirect_intended");
        if (redirect_intended) {
            localStorage.setItem("redirect_intended", redirect_intended);
        }
    }, []);
    // if (isLoggedIn()) {
    //     localStorage.setItem(
    //         "redirect_intended",
    //         history.location.pathname + history.location.search
    //     );
    //     return <Redirect to={"/"} />;
    // }
    const onSubmit = async (e) => {
        e.preventDefault();
        // console.log("submitted");
        dispatch({ type: "register" });
        try {
            setLoading(true);
            const redirect_intended = localStorage.getItem("redirect_intended");
            let productId;
            if (redirect_intended.match("buy")) {
                productId = redirect_intended
                    .split("?")?.[0]
                    ?.split("/")
                    ?.pop();
            }
            const response = await register({
                password,
                last_name,
                first_name,
                email,
                productId,
                phone_number,
            });
            setLoading(false);
            // console.log(response);
            if (response.data) {
                alert.success(response.data.message);
                dispatch("success");
                history.push(`/verify/email?fromAd=${fromAd}&email=` + email);
                // alert.success("Please login now");
            } else {
                alert.error(response.message);
                dispatch({ type: "error" });
            }
        } catch (e) {
            // console.log(e);
            setLoading(false);

            let message = "An error occurred";
            if (e.response && e.response.data) {
                message = e.response.data.message;
                if (e?.response?.data?.error) {
                    message = Object.values(e.response.data.error).join(",\n");
                }
                dispatch({ type: "error", value: e.response.data.error });
            }
            alert.error(message);
        }
    };
    return (
        <div className="text-black h-screen">
            {(is_loading || loading) && <Loader />}
            <div className="md:flex justify-between items-center py-5">
                <div className="fixed flex items-center justify-between px-10 top-0 h-16 left-0 right-0 bg-white font-black text-3xl gap-4 text-gray-800 ">
                    <img
                        src={require("./../../assets/odiopay-logo.svg")}
                        alt={"Odio Logo"}
                    />
                    <p className="mr-10 text-base font-normal flex items-center justify-end mt-5 md:mt-0">
                        <span className="whitespace-no-wrap hidden md:block">
                            Already a member?{" "}
                            <Link className="text-red" to={"/login"}>
                                Sign in
                            </Link>
                        </span>
                        {/* <i className="material-icons ml-3 p-1 bg-white rounded-full text-black shadow-lg">
                            more_vert
                        </i> */}
                    </p>
                </div>
            </div>
            <div className="flex flex-row items-center border h-full p-10 ">
                <div className="md:flex w-full max-w-3xl md:mx-auto mt-2 shadow-md">
                    <div
                        className="hidden relative lg:block bg-k-blue w-1/2"
                        style={{
                            backgroundImage: `url(${bgImage})`,
                            backgroundPosition: "center",
                            backgroundRepeat: "no-repeat",
                        }}
                    >
                        {/* <img
                            src={require("./../../assets/ODIO-LADY.png")}
                            alt=""
                            // className="w-full pt-10 h-full"
                            className="hidden md:block w-1/2 relative black-man-bg"
                        /> */}
                    </div>
                    <div className="w-full lg:w-1/2 bg-white pr-5 pb-3 pl-5 md:pl-10 border-t-4 border-k10-blue pt-5">
                        <div className="container">
                            <h3 className="font-bold text-2xl text-center text-k-purple mb-5">
                                Sign up for Odiopay
                            </h3>
                            {/*<div className="mt-10 flex space-y-3 md:space-y-0 md:space-x-4 flex-col md:flex-row ">*/}
                            {/*    <button className="bg-blue-100 shadow-lg text-blue-900 font-semibold py-2 px-4 rounded-full">*/}
                            {/*        <img*/}
                            {/*            className="h-10 inline pr-3"*/}
                            {/*            src={require("./../../assets/gimg.png")}*/}
                            {/*            alt="google sign in"*/}
                            {/*        />{" "}*/}
                            {/*        Sign in with Google*/}
                            {/*    </button>*/}
                            {/*    <button className="bg-blue-100 shadow-lg text-blue-900 font-semibold py-2 px-4 rounded-full">*/}
                            {/*        <img*/}
                            {/*            className="h-10 inline pr-3"*/}
                            {/*            src={require("./../../assets/fimg.png")}*/}
                            {/*            alt="facebook sign in"*/}
                            {/*        />{" "}*/}
                            {/*        Sign in with Facebook*/}
                            {/*    </button>*/}
                            {/*</div>*/}

                            {/*<div className="mt-8 w-10">*/}
                            {/*    <hr />*/}
                            {/*</div>*/}

                            {/*<p className="text-gray-500 text-sm mt-5 mb-10">*/}
                            {/*    Or sign up with email address*/}
                            {/*</p>*/}

                            <form onSubmit={onSubmit}>
                                {/* <div className="md:flex mt-5"> */}
                                <div className="md:w-full md:3r-8">
                                    <label>First Name</label>
                                    <input
                                        required
                                        name="first_name"
                                        type="text"
                                        value={first_name}
                                        onChange={(e) =>
                                            dispatch({
                                                type: "field",
                                                field: "first_name",
                                                value: e.currentTarget.value,
                                            })
                                        }
                                        className="block w-full bg-blue-100 h-12"
                                    />
                                </div>
                                <div className="md:w-full mt-3 md:mt-0">
                                    <label>Last Name</label>
                                    <input
                                        required
                                        name="last_name"
                                        type="text"
                                        value={last_name}
                                        onChange={(e) =>
                                            dispatch({
                                                type: "field",
                                                field: "last_name",
                                                value: e.currentTarget.value,
                                            })
                                        }
                                        className="block w-full bg-blue-100 h-12"
                                    />
                                </div>
                                {/* </div> */}
                                {/* <div className="md:flex mt-5"> */}
                                <div className="md:w-full md:3r-8">
                                    <label>Email</label>
                                    <input
                                        required
                                        name="email_address"
                                        type="email"
                                        value={email}
                                        onChange={(e) =>
                                            dispatch({
                                                type: "field",
                                                field: "email",
                                                value: e.currentTarget.value,
                                            })
                                        }
                                        className="block w-full bg-blue-100 h-12"
                                    />
                                    {error?.email && (
                                        <span className="text-red-600">
                                            {error?.email}
                                        </span>
                                    )}
                                </div>
                                <div className="md:w-full md:3r-8">
                                    <label>Phone Number</label>
                                    <input
                                        required
                                        name="phone_number"
                                        type="number"
                                        value={phone_number}
                                        onChange={(e) => {
                                            console.log(e.target.value);
                                            if (
                                                e.target.value &&
                                                String(e.target.value)
                                                    .length !== 11
                                            ) {
                                                console.log("here");
                                                setPhoneError(
                                                    "Phone number length must be 11"
                                                );
                                            } else {
                                                setPhoneError("");
                                            }

                                            dispatch({
                                                type: "field",
                                                field: "phone_number",
                                                value: e.currentTarget.value,
                                            });
                                        }}
                                        className="block w-full bg-blue-100 h-12"
                                    />
                                    {(error?.phone_number || phoneError) && (
                                        <span className="text-red-600">
                                            {error?.phone_number || phoneError}
                                        </span>
                                    )}
                                </div>
                                <div className="md:w-full mt-3 md:mt-0">
                                    <label className="flex items-center">
                                        Password{" "}
                                        {/* <PasswordHint
                                            show={hint}
                                            password={password}
                                        /> */}
                                    </label>
                                    <PasswordField
                                        required
                                        name="password"
                                        type="password"
                                        value={password}
                                        // pattern={config.PASSWORD_PATTERN}
                                        onFocus={() => showHint(true)}
                                        onBlur={() => showHint(false)}
                                        onChange={(e) =>
                                            dispatch({
                                                type: "field",
                                                field: "password",
                                                value: e.currentTarget.value,
                                            })
                                        }
                                        className="block w-full bg-blue-100 h-12 "
                                    />
                                    {error?.password && (
                                        <span className="text-red-600">
                                            {error?.password}
                                        </span>
                                    )}
                                </div>
                                {/* </div> */}
                                <div className="mt-5">
                                    <input
                                        required
                                        className="h-4 w-4 bg-blue-400 mr-3 "
                                        value={agree}
                                        onChange={(e) =>
                                            dispatch({
                                                type: "field",
                                                field: "agree",
                                                value: e.currentTarget.checked,
                                            })
                                        }
                                        type="checkbox"
                                        name="agree"
                                    />
                                    <span>
                                        Creating an account means you’re okay
                                        with our{" "}
                                        <a
                                            className="font-semibold"
                                            href="/terms-of-service.html"
                                            target="_blank"
                                        >
                                            Terms of Service
                                        </a>{" "}
                                        and,{" "}
                                        <a
                                            className="font-semibold"
                                            href="/privacy-policy.html"
                                            target="_blank"
                                        >
                                            Privacy Policy
                                        </a>
                                        {/* , and our default{" "}
                                    <Link
                                        className="font-semibold"
                                        to="/privacy-policy"
                                    >
                                        Notification Settings
                                    </Link> */}
                                        .
                                    </span>
                                </div>
                                <div className="flex justify-center w-full">
                                    <button
                                        className={
                                            (!agree ? "opacity-50" : "") +
                                            " mt-8 rounded-full focus:outline-none bg-primary text-white font-bold text-xl py-3 px-16"
                                        }
                                    >
                                        Create Account
                                    </button>
                                </div>
                                <div className="mt-5 space-y-3 md:space-y-0 md:space-x-4 flex justify-center whitespace-no-wrap items-center">
                                    <button
                                        className="bg-blue-100 text-blue-900 font-semibold py-2 px-4 rounded-full"
                                        onClick={handleGoogle}
                                        disabled={googleLoading}
                                    >
                                        <img
                                            className="h-8 inline pr-3"
                                            src={require("./../../assets/gimg.png")}
                                            alt="google sign in"
                                        />{" "}
                                        Sign up with Google
                                    </button>
                                    {/* <button
                                        type="submit"
                                        className="bg-blue-100 focus:outline-none text-blue-900 font-semibold py-2 px-4 rounded-full"
                                    >
                                        <img
                                            className="h-8 inline pr-3"
                                            src={require("./../../assets/fimg.png")}
                                            alt="facebook sign in"
                                        />{" "}
                                        Sign in with Facebook
                                    </button> */}
                                </div>
                                <div className="flex justify-center mt-5">
                                    <span className="whitespace-no-wrap block md:hidden">
                                        Already a member?{" "}
                                        <Link
                                            className="text-red"
                                            to={"/login"}
                                        >
                                            Sign in
                                        </Link>
                                    </span>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export function PasswordHint({ password, show, align = "left" }) {
    const [hint, showHint] = useState(false);

    const validate = (pattern) => {
        const re = new RegExp(pattern);
        const test = re.test(password);
        return test;
    };

    return (
        <Tooltip
            title="Password hint"
            position="top"
            arrow={true}
            open={show || hint}
            theme={"light"}
            html={
                <ul className="text-left text-xs list-disc space-y-2">
                    <li className="flex items-center">
                        <ShowCheckMark
                            show={validate("[A-Z]")}
                            text={
                                "Passwords must contain at least 1 upper case letter"
                            }
                        />
                    </li>
                    <li className="flex items-center">
                        <ShowCheckMark
                            show={validate("[a-z]")}
                            text="Passwords must contain at least 1 lower case letter"
                        />
                    </li>
                    <li className="flex items-center">
                        <ShowCheckMark
                            show={validate("[@$!%*#?&]")}
                            text="Passwords must contain at least 1 special character"
                        />
                    </li>
                    <li className="flex items-center">
                        <ShowCheckMark
                            show={validate("[0-9]")}
                            text="Passwords must contain at least 1 number"
                        />
                    </li>
                    <li className="flex items-center">
                        <ShowCheckMark
                            show={password.length > 8}
                            text="Passwords must contain at least 8 characters in length"
                        />
                    </li>
                </ul>
            }
        >
            <i className="material-icons text-sm text-blue-500 pl-2">
                help_outline
            </i>
        </Tooltip>
    );
}

function ShowCheckMark({ show, text }) {
    return (
        <>
            {show ? (
                <i className="material-icons text-sm pr-2 text-green-600">
                    check_circle
                </i>
            ) : (
                <i className="pr-6"></i>
            )}
            <span className={show && "font-bold leading-tight"}>{text}</span>
        </>
    );
}
