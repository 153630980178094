import React, {useMemo} from "react";
export function useObjectData(initialState){
    const [data, setData] = React.useState(() => {
        if(initialState && typeof initialState === 'function'){
            return initialState()
        }
        if(!initialState || typeof initialState !== 'object'){
            return {}
        }
        return initialState
    })
    const handleDataChange = (change, all = false) => {
        if(all){
            setData((prevState) =>  ({...change}))
        }else{
            setData((prevState ) => ({
                ...prevState,
                ...change
            }))
        }
    }
    return {
        set: handleDataChange,
        get: data
    }
}
export function useSingleData(initialState) {
    const [data, setData] = React.useState(initialState)
    return {
        set: setData,
        get: data
    }
}

export function useGroupData(initialState, guess = false) {
    const data = useObjectData(() => {
        if(typeof initialState !== 'object'){
            throw 'Invalid initial state'
        }
        if(guess) {
            return initialState.reduce((obj, item, idx) => {
                return {
                    ...obj,
                    [idx+'d']: item
                }
            }, {})
        }
        return initialState
    })
    return Object.keys(data.get).map( v => ({
        get: data.get[v],
        set: (c) => {
            data.set({[v]: c})}
    }))
}
