import React, { useContext, useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { config } from "../utils/constants";
import { UserContext } from "../contexts/user_context";
import menuSvg from "../assets/menu-svgrepo-com.svg";
export function Header() {
    const { user, fetchUserDetails, dashboard } = useContext(UserContext);
    const [showNav, setShowNav] = useState(false);
    const logout = () => {
        localStorage.removeItem(config.TOKEN_NAME);
        localStorage.removeItem(config.TOKEN_NAME + "_expiry");
        history.push("/");
    };
    useEffect(() => {
        if (!user.id) {
            fetchUserDetails();
        }
    }, [user]);
    const history = useHistory();
    const path = "/" + history.location.pathname.split("/")[1];
    const headerText = [
        { name: "", link: "/dashboard" },
        { name: "", link: "/where-to-buy" },
        { name: "", link: "/enjoy-now" },
        { name: "", link: "/payments" },
        { name: "", link: "/wallet" },
        { name: "", link: "/activities" },
        { name: "", link: "/settings" },
    ].find((elem) => elem.link === path)?.name;
    console.log({ path, realPath: history.location.pathname });
    return (
        <nav className="bg-white shadow-md">
            <div className="relative mx-auto flex w-full py-2 items-center justify-between whitespace-no-wrap border-b-2">
                <Link
                    to={"/"}
                    className="ml-3 text-k5-gray text-lg font-bold flex items-center gap-2"
                >
                    {/* <img
                        alt=""
                        src={require("./../assets/new-logo.svg")}
                    /> */}
                    <img
                        className="h-12 mt-5 md:hidden"
                        src={require("../assets/odiopay-logo.svg")}
                        alt={"Odio Logo"}
                    />
                    <span className="hidden md:flex">{headerText || ""}</span>
                </Link>
                <div>
                    <div className="hidden md:flex text-k-indigo justify-between md:justify-end items-center md:mr-5">
                        <Link to={"/settings"}>
                            <h5 className="flex items-center mr-2">
                                {user.first_name} {user.last_name}
                            </h5>
                        </Link>
                        <Avatar />
                        <Link
                            to={"/support"}
                            className="text-base material-icons px-3 hidden md:inline-block"
                        >
                            mail
                        </Link>
                        <button
                            className="text-base material-icons hidden md:inline-block"
                            onClick={logout}
                        >
                            <i className="text-base material-icons hidden md:inline-block">
                                logout
                            </i>
                        </button>
                    </div>
                    <div className="flex md:hidden">
                        <Avatar forMobile />
                    </div>
                </div>
            </div>
        </nav>
    );
}

function Avatar({ forMobile = false }) {
    const [drop, setDrop] = useState(false);
    const { user } = useContext(UserContext);
    const history = useHistory();
    const logout = () => {
        localStorage.removeItem(config.TOKEN_NAME);
        localStorage.removeItem(config.TOKEN_NAME + "_expiry");
        history.push("/");
    };
    return (
        <div id="profile-avatar" className="relative">
            <div
                onClick={() => setDrop(!drop)}
                className={`rounded-full cursor-pointer ml-2 flex items-center`}
            >
                <div
                    className={`cursor-pointer rounded-full h-12 w-12 bg-k-pink bg-opacity-25 relative ${
                        forMobile ? "hidden" : ""
                    }`}
                >
                    {user.passport ? (
                        <img
                            className="rounded-full w-full h-full"
                            alt={""}
                            src={user.passport}
                        />
                    ) : (
                        <h6 className="h-full w-full flex items-center justify-center text-xl font-light">
                            {user?.first_name?.[0]?.toUpperCase()}{" "}
                            {user?.last_name?.[0]?.toUpperCase()}
                        </h6>
                    )}
                </div>
                <i
                    className={
                        "material-icons px-2" + forMobile ? "hidden" : "block"
                    }
                >
                    expand_more
                </i>
                <img
                    src={menuSvg}
                    alt="menu"
                    className={forMobile ? "block w-10 pr-5" : "hidden"}
                />
            </div>
            {drop && (
                <div
                    onMouseLeave={() => setDrop(false)}
                    className="shadow-lg bg-white absolute right-0 md:right-auto onClick={() => setDropfalse} md:left-0 z-20 "
                >
                    <ul className="py-4 px-8 text-xs text-k-indigo whitespace-no-wrap">
                        <li
                            onClick={() => setDrop(false)}
                            className="py-2 cursor-pointer"
                        >
                            <Link to={"/settings"}>Manage account</Link>
                        </li>
                        <li
                            onClick={() => setDrop(false)}
                            className="py-2 cursor-pointer"
                        >
                            <Link to={"/enjoy-now"}>EnjoyNow</Link>
                        </li>
                        <li
                            onClick={() => setDrop(false)}
                            className="py-2 cursor-pointer"
                        >
                            <Link to={"/support"}>Support</Link>
                        </li>
                        <li
                            onClick={() => setDrop(false)}
                            className="py-2 cursor-pointer"
                        >
                            FAQ
                        </li>
                        <li onClick={logout} className="py-2 cursor-pointer">
                            Logout
                        </li>
                    </ul>
                </div>
            )}
        </div>
    );
}
