import React, { useContext, useState } from "react";

import { useAlert } from "react-alert";
import { Link } from "react-router-dom";

import Loader from "../../components/loader";
import { PasswordField } from "../../components/password_field";
import { getGoogleURL, login } from "../../utils/api";
import { config } from "../../utils/constants";
import { UserContext } from "../../contexts/user_context";
import loginImageLocal from "../../assets/image 23.png";
export default function Login({ history }) {
  const handleGoogle = async () => {
    try {
      setGoogleLoading(true);
      const urlData = await getGoogleURL();
      if (urlData.data) {
        window.open(urlData.data.url, "_self");
      } else {
        setGoogleLoading(false);
        alert.error(urlData.message);
        window.open("/login", "_self");
      }
    } catch (e) {
      window.open("/login", "_self");
      setGoogleLoading(false);
      let message = "An error occurred";
      alert.error(message);
    }
  };
  const [googleLoading, setGoogleLoading] = useState(false);
  const { fetchUserDetails } = useContext(UserContext);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, showLoading] = useState(false);
  const alert = useAlert();
  // if (isLoggedIn()) {
  //     localStorage.setItem(
  //         "redirect_intended",
  //         history.location.pathname + history.location.search
  //     );
  //     return <Redirect to={"/"} />;
  // }
  const onSubmit = async (e) => {
    e.preventDefault();
    showLoading(true);
    try {
      const response = await login({ email, password });
      // console.log(response);
      if (response.data) {
        if (response.data.user.type === "customer") {
          localStorage.setItem(config.TOKEN_NAME, response.data.token);
          localStorage.setItem(config.TOKEN_TYPE, response.data.token_type);
          localStorage.setItem(
            config.TOKEN_NAME + "_expiry",
            response.data.expires_in_sec,
          );
          fetchUserDetails();
          alert.success(response.data.message);
          const r_i = localStorage.getItem("redirect_intended");
          console.log({ r_i });
          if (r_i) {
            localStorage.removeItem("redirect_intended");
            history.push(r_i);
          } else {
            history.push("/");
          }
        } else {
          alert.error("Not a customer");
        }
      } else {
        alert.error(response.message);
      }
    } catch (e) {
      let message =
        (e.response && e.response.data && e.response.data.message) ||
        "An error occurred";
      if (e?.response?.data?.error) {
        message = Object.values(e.response.data.error).join(",\n");
      }
      if (message === "inactive") {
        message = "Please verify your email";
        history.push("/verify/email?email=" + email);
      }
      alert.error(message);
    } finally {
      showLoading(false);
    }
  };
  return (
    <>
      {isLoading && <Loader />}
      <div className="fixed flex items-center px-10 top-0 h-16 left-0 right-0 bg-white font-black text-3xl gap-4 text-gray-800 shadow-md">
        <img
          src={require("./../../assets/odiopay-logo.svg")}
          alt={"Odio Logo"}
        />
      </div>
      <div className="min-h-screen flex flex-col items-center justify-center">
        <div className="w-full max-w-3xl shadow-md mx-auto mt-8">
          <div className="flex w-full">
            <div
              style={{
                backgroundImage: `url(${loginImageLocal})`,
              }}
              className="hidden md:block w-1/2 relative black-man-bg"
            >
              {/* <p className="absolute bottom-0 mb-6 text-small text-white ml-6">
                                &copy; Copyright {new Date().getFullYear()}{" "}
                                Odiopay
                            </p> */}
              <img src={loginImageLocal} alt="" />
            </div>
            <div className="w-full mx-4 md:mx-0 md:w-1/2 relative bg-white border-t-4 border-k10-blue text-gray-700">
              <div className="mx-8">
                <form onSubmit={onSubmit}>
                  <h3 className="text-lg font-bold text-center mt-5 mb-5 ">
                    Log in to your Odiopay account
                  </h3>

                  <div className="">
                    <label className="block">
                      <span className="text-gray-600">Email</span>
                      <input
                        value={email}
                        onChange={(e) => setEmail(e.currentTarget.value)}
                        required
                        type="email"
                        className="border h-10 rounded-sm block mt-2 bg-transparent pl-3 outline-none w-full focus:outline-none"
                        placeholder="Email"
                      />
                    </label>
                  </div>
                  <div className="">
                    <label className="block mt-3">
                      <span className="text-gray-600">Password</span>
                      <PasswordField
                        value={password}
                        onChange={(e) => setPassword(e.currentTarget.value)}
                        required
                        type="password"
                        placeholder="Password"
                        className="border h-10 rounded-sm block mt-2 bg-transparent pl-3 outline-none w-full focus:outline-none"
                      />
                    </label>
                  </div>

                  <button className="bg-primary text-white rounded w-full py-3 px-16 my-2 font-bold">
                    Login
                  </button>
                </form>
                {googleLoading && <Loader />}
                <div className="mt-5 space-y-3 md:space-y-0 md:space-x-4 flex justify-center whitespace-no-wrap items-center">
                  <button
                    className="bg-blue-100 text-blue-900 font-semibold py-2 px-4 rounded-full"
                    onClick={handleGoogle}
                    disabled={googleLoading}
                  >
                    <img
                      className="h-8 inline pr-3"
                      src={require("./../../assets/gimg.png")}
                      alt="google sign in"
                    />{" "}
                    Sign in with Google
                  </button>
                  {/* <button
                                        type="submit"
                                        className="bg-blue-100 focus:outline-none text-blue-900 font-semibold py-2 px-4 rounded-full"
                                    >
                                        <img
                                            className="h-8 inline pr-3"
                                            src={require("./../../assets/fimg.png")}
                                            alt="facebook sign in"
                                        />{" "}
                                        Sign in with Facebook
                                    </button> */}
                </div>
                <div className="mt-5 flex items-center justify-center text-md">
                  Forgot password?
                  <Link
                    to={"/password/forgot"}
                    className="flex font-light items-center ml-2 text-k10-blue"
                  >
                    Reset your password{" "}
                    <i className="material-icons text-base">arrow_forward</i>
                  </Link>
                </div>
                <div className="mb-5 mt-1 flex text-small items-center justify-center text-md">
                  Don't have an account?
                  <Link
                    to={"/register"}
                    className="flex font-light items-center ml-2 text-k10-blue"
                  >
                    Sign Up Now
                    <i className="material-icons text-base">arrow_forward</i>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
