import React, { useContext, useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useHistory } from "react-router-dom";
import { UserContext } from "../contexts/user_context";
import { config } from "../utils/constants";
import Loader from "./loader";
const LoginGoogle = () => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [data, setData] = useState({});
    const history = useHistory();
    const { fetchUserDetails } = useContext(UserContext);
    const alert = useAlert();
    useEffect(() => {
        console.log(history.location.search);
        fetch(
            `${config.API_URL}/auth/google/callback${history.location.search}`,
            { headers: new Headers({ accept: "application/json" }) }
        )
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error("Something went wrong!");
            })
            .then((response) => {
                console.log(response);
                if (response.user.type === "customer") {
                    localStorage.setItem(config.TOKEN_NAME, response.token);
                    localStorage.setItem(
                        config.TOKEN_TYPE,
                        response.token_type
                    );
                    localStorage.setItem(
                        config.TOKEN_NAME + "_expiry",
                        response.expires_in_sec
                    );
                    fetchUserDetails();
                    alert.success(response.message);
                    const r_i = localStorage.getItem("redirect_intended");
                    console.log({ r_i });
                    if (r_i) {
                        localStorage.removeItem("redirect_intended");
                        history.push(r_i);
                    } else {
                        history.push("/");
                    }
                } else {
                    alert.error("Not a customer");
                    history.push("/login");
                }
            })
            .catch((error) => {
                setLoading(false);
                setError(error);
            });
    }, []);
    return <Loader />;
};
export default LoginGoogle;
