import React from "react";
import { useState } from "react";
import { Link, useHistory } from "react-router-dom";

const side_data = [
    {
        name: "Overview",
        id: "dashboard",
        icon: require("../assets/home.svg"),
        selectedIcon: require("../assets/home_selected.svg"),
        link: "/dashboard",
        mobile: true,
    },
    {
        name: "Credit Limit",
        id: "credit-limit",
        icon: require("../assets/enjoynow.svg"),
        selectedIcon: require("../assets/enjoynow_selected.svg"),
        link: "/credit-limit",
        mobile: true,
    },
    {
        name: "Shop",
        id: "where-to-buy",
        icon: require("../assets/shop.svg"),
        selectedIcon: require("../assets/shop_selected.svg"),
        link: "/where-to-buy",
        mobile: true,
    },
    // {
    //     name: "Stores",
    //     id: "where-to-buy",
    //     icon: require("../assets/store-black.svg"),
    //     selectedIcon: require("../assets/store-blue.svg"),
    //     link: "/where-to-buy",
    //     mobile: true,
    // },
    {
        name: "Orders",
        id: "orders",
        icon: require("../assets/bills.svg"),
        selectedIcon: require("../assets/bills_selected.svg"),
        link: "/orders",
        mobile: true,
    },
    // {
    //     name: "My Wallet",
    //     id: "wallet",
    //     icon: require("../assets/wallet-sidebar.svg"),
    //     selectedIcon: require("../assets/wallet-sidebar_selected.svg"),
    //     link: "/wallet",
    //     mobile: false,
    // },
    {
        name: "History",
        id: "activity",
        icon: require("../assets/history.svg"),
        selectedIcon: require("../assets/history_selected.svg"),
        // link: "/activities",
        link: "/payments",
        mobile: true,
    },
    {
        name: "profile",
        id: "profile",
        icon: require("../assets/profile.svg"),
        selectedIcon: require("../assets/profile_selected.svg"),
        // link: "/activities",
        link: "/settings",
        onlyMobile: true,
        mobile: false,
    },
];
export function SideBar() {
    const history = useHistory();
    const path = "/" + history.location.pathname.split("/")[1];
    const [inside, setInside] = useState(true);
    const onhoverout = (e) => {
        // setInside(false);
        console.log("hovered out");
    };
    const onhoverin = (e) => {
        // setInside(true);
        console.log("hovered in");
    };
    return (
        <div
            onMouseEnter={onhoverin}
            onMouseLeave={onhoverout}
            className={`fixed bottom-0 left-0 right-0 z-10 md:static h-20 md:h-screen md:overflow-y-auto ${
                inside ? "md:w-50" : "md:w-24"
            } px-3 bg-white  md:flex md:flex-col justify-around md:justify-start  md:pt-2 border-2`}
        >
            <Link
                className="mt-6 hidden md:flex text-3xl gap-4 text-gray-800 font-extrabold"
                to={"/"}
            >
                {/* <img
                    className="mb-16"
                    alt={""}
                    src={require("../assets/odio-pay-logo-sky-blue.svg")}
                /> */}
                <img
                    src={require("../assets/odiopay-logo.svg")}
                    alt={"Odio Logo"}
                />
            </Link>
            <div className="flex-1 md:mb-10 grid grid-cols-5 md:flex flex-col mt-0 md:mt-10 md:ml-3">
                {side_data.map((data) => (
                    <Link
                        className={`my-1 md:my-2 mr-3 px-3 md:mx-0 flex-col md:flex-row gap-0 md:gap-4 justify-start items-center ${
                            path === data.link ? "bg-blue-100" : ""
                        } rounded-full  ${
                            data.mobile ? "flex" : "hidden md:flex"
                        } ${data.onlyMobile ? "flex md:hidden" : ""}`}
                        to={data.link}
                        id={data.id}
                        key={data.id}
                    >
                        <img
                            src={
                                path === data.link
                                    ? data.selectedIcon
                                    : data.icon
                            }
                            className={
                                data.name
                                    ? "w-4 md:w-6 h-8"
                                    : "w-6 md:w-10 h-10"
                            }
                            alt={""}
                        />
                        {inside && (
                            <span className="text-center text-sm md:text-base font-auto md:font-semibold text-primary whitespace-no-wrap">
                                {data.name}
                            </span>
                        )}
                    </Link>
                ))}
            </div>
        </div>
    );
}
