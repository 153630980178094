import React, { useEffect } from 'react';

export function Modal({ children }) {
    return (
        <div className="fixed top-0 bottom-0 right-0 left-0 h-screen w-screen z-30 bg-black-op25">
            <div
                style={{
                    minWidth: "250px",
                    minHeight: "300px",
                    maxHeight: "80vh",
                    overflow: "auto",
                }}
            >
                {children}
            </div>
        </div>
    );
}

export function useOutsideAlerter(ref, handler) {
    useEffect(() => {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                handler();
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);
}
