import React, { useState, createContext } from "react"
import { useCustomerDetails } from "../hooks/fetch_hooks"
import { useDashboard } from "./dashboard_context"

export const UserContext = createContext()

export function UserProvider(props) {
  const [user, isFetching, fetchUserDetails] = useCustomerDetails()
  const dashboard = useDashboard()

  //add other functions later
  //update user probably
  return (
    <UserContext.Provider
      value={{
        user,
        fetching_user: isFetching,
        fetchUserDetails,
        dashboard
      }}
    >
      {props.children}
    </UserContext.Provider>
  )
}
